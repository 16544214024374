<template>
  <div class="trialClass" v-title="'试听课程'">
    <div class="form">
      <div class="form-item">
        <input class="input" type="text" v-model="name" placeholder="姓名">
      </div>
      <div class="form-item">
        <input class="input" type="text" v-model="job" placeholder="职业">
      </div>
      <div class="form-item">
        <input class="input" type="tel" v-model="phone" placeholder="手机">
      </div>
      <button class="btn-submit" @click="submit">预约试听</button>
    </div>
    <p class="desc">报名成功后，5个工作日内将有官方客服对您进行 电话回访请认准西门子官方课程授权合作伙伴“工业速派”， 切勿轻信其它非授权机构您的预留的信息将严格保密，敬请放心</p>
    <div class="foot">
      <img class="" src="static/h5/siemens/footer.png" alt="">
    </div>
  </div>
</template>
<script>
import validate from '@/common/validate.js';
import {
  SignUp
} from "@/common/api.js";
export default {
  data(){
    return{
      name:'',
      job:'',
      phone:'',
      cityData:{}
    }
  },
  methods: {
    //提交
    submit(){
      if (this.job.trim() == '') {
          this.$toast('请输入姓名');
          return false;
      }
      if (this.job.trim() == '') {
          this.$toast('请输入职业');
          return false;
      }
      if (this.phone.trim() == '') {
          this.$toast('请输入手机号');
          return false;
      }
      if (!validate.phone(this.phone)) {
          this.$toast('手机号格式不正确');
          return false;
      }
      let params={
        Name:this.name,
        Phone:this.phone,
        Profession:this.job,
        SignType:2,
        ...this.$route.query
      };
      if ((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
          params['ClientType'] = 2;
      } else {
          params['ClientType'] = 1;
      }
      SignUp(params).then(r=>{
        this.$toast(r.Message);
      })
    }
  },
}
</script>
<style lang="scss">
.trialClass{
  max-width: 10rem;
  margin: 0 auto;
  min-height: 100vh;
  background-color: #fff;
  overflow: hidden;
  .form{
    width: 8rem;
    margin: 0.88rem auto 0.853333rem;
    .form-item{
      width: 100%;
      margin-bottom: 20px;
    }
    .input{
      height: 40px;
      border: 1px solid #999;
      background-color: transparent;
      color: #333;
      font-size: 16px;
      padding: 8px 12px;
      line-height: 22px;
      display: block;
      width: 100%;
    }
    .btn-submit{
      display: block;
      width: 100%;
      height: 40px;
      background-color: #FF7E16;
      font-size: 16px;
      color: #fff;
      line-height: 40px;
    }
  }
  .desc{
    width: 8.8rem;
    font-size: 12px;
    color: #999;
    line-height: 20px;
    margin: 0 auto;
    text-align: center;
  }
  .foot{
    margin-top: 3.2rem;
    img{
      display: block;
      margin: 0 auto;
      width: 8.866667rem;
    }
  }
}
</style>